/** @jsxImportSource theme-ui */
import { Grid } from "@material-ui/core";
import Title from "components/general/Title";
import * as React from "react";

import OpportunitiesDataGrid from "components/opportunities/datagrid";

type Props = Record<string, never>;
const opTableRows = [
  {
    id: 17,
    checkName: "idle_aws_ebs_volumes",
    uniqueid: "idle_aws_ebs_volumes_922949123117_vol-04b04c4b146aea7ca",
    version: 1,
    detailType: "Idle AWS EBS Volumes",
    source: "score.playbook",
    account: 922949123117,
    region: "us-east-1",
    time: "1/3/20 11:23",
    resources: "['vol-04b04c4b146aea7ca']",
    resourceId: "vol-04b04c4b146aea7ca",
    resourceArn:
      "arn:aws:ec2:us-east-1:922949123117:volume:vol-04b04c4b146aea7ca",
    serviceType: "ec2",
    resourceType: "volume",
    currentCost: 4.8,
    savings: 4.8,
    playbookId: "",
    runbookId: null,
    tagged: null,
    executionWindowStart: null,
    executionWindowEnd: null,
    dateExecuted: null,
    executionDetails: null,
    jiraCRTicket: null,
    jiraSOUTicket: "CO-15",
    pcaApproved: null,
    pcaReviewDate: null,
    pcaComment: null,
    jiraEXTicket: null,
    status: "Pending Approval",
    customRunbookId: null,
    pcaRejectCategory: null,
    last_scan_begin: null,
    last_opp_find: "2/3/20 18:00",
    deprecated: 1,
    approvaljiraid: "tmp-e0779b81-f225-4799-89de-e40e8883dfb7",
    cr_status: null,
    workflow_type: "CO Opportunity",
    isCustomized: 0,
    excreationdate: null,
    additional_labels: null,
    overrideprojectkey: null,
    cloudType: "AWS",
    VCSIssue: null,
    VCSBranch: null,
    FixCommit: null,
  },
  {
    id: 50953,
    checkName: "aws_rds_backups",
    uniqueid: "aws_rds_backups_086775481754_kayakoc-6077-k4-rds05-new-upgrade",
    version: 1,
    detailType: "AWS RDS Backups",
    source: "score.playbook",
    account: 86775481754,
    region: "us-east-1",
    time: "4/2/20 10:17",
    resources: "['kayakoc-6077-k4-rds05-new-upgrade']",
    resourceId: "kayakoc-6077-k4-rds05-new-upgrade",
    resourceArn:
      "arn:aws:rds:us-east-1:086775481754:snapshot:kayakoc-6077-k4-rds05-new-upgrade",
    serviceType: "rds",
    resourceType: "snapshot",
    currentCost: 570,
    savings: 570,
    playbookId: "",
    runbookId: null,
    tagged: "11/16/19 14:05",
    executionWindowStart: null,
    executionWindowEnd: null,
    dateExecuted: null,
    executionDetails: null,
    jiraCRTicket: null,
    jiraSOUTicket: "CO-76901",
    pcaApproved: null,
    pcaReviewDate: null,
    pcaComment: null,
    jiraEXTicket: null,
    status: "Pending Approval",
    customRunbookId: null,
    pcaRejectCategory: null,
    last_scan_begin: null,
    last_opp_find: "4/2/20 10:18",
    deprecated: 1,
    approvaljiraid: "tmp-85e080d8-8994-44fb-9282-b760f76861d4",
    cr_status: null,
    workflow_type: "CO Opportunity",
    isCustomized: 0,
    excreationdate: null,
    additional_labels: null,
    overrideprojectkey: null,
    cloudType: "AWS",
    VCSIssue: null,
    VCSBranch: null,
    FixCommit: null,
  },
  {
    id: 50955,
    checkName: "aws_rds_backups",
    uniqueid: "aws_rds_backups_086775481754_rds09-kcupgrade-05052019",
    version: 1,
    detailType: "AWS RDS Backups",
    source: "score.playbook",
    account: 86775481754,
    region: "us-east-1",
    time: "4/2/20 10:17",
    resources: "['rds09-kcupgrade-05052019']",
    resourceId: "rds09-kcupgrade-05052019",
    resourceArn:
      "arn:aws:rds:us-east-1:086775481754:snapshot:rds09-kcupgrade-05052019",
    serviceType: "rds",
    resourceType: "snapshot",
    currentCost: 798,
    savings: 798,
    playbookId: "",
    runbookId: null,
    tagged: "11/16/19 14:05",
    executionWindowStart: null,
    executionWindowEnd: null,
    dateExecuted: null,
    executionDetails: null,
    jiraCRTicket: null,
    jiraSOUTicket: null,
    pcaApproved: "1",
    pcaReviewDate: "1/28/20 13:54",
    pcaComment: null,
    jiraEXTicket: null,
    status: "Unknown",
    customRunbookId: null,
    pcaRejectCategory: null,
    last_scan_begin: null,
    last_opp_find: "4/2/20 10:18",
    deprecated: 1,
    approvaljiraid: null,
    cr_status: null,
    workflow_type: null,
    isCustomized: 0,
    excreationdate: null,
    additional_labels: null,
    overrideprojectkey: null,
    cloudType: "AWS",
    VCSIssue: null,
    VCSBranch: null,
    FixCommit: null,
  },
  {
    id: 50956,
    checkName: "aws_rds_backups",
    uniqueid:
      "aws_rds_backups_086775481754_rds:k4-rds09-new-bkp-oct-15-2019-2019-10-17-08-11",
    version: 1,
    detailType: "AWS RDS Backups",
    source: "score.playbook",
    account: 86775481754,
    region: "us-east-1",
    time: "11/13/19 11:28",
    resources: "['kayakoc-6077-k4-rds05-new-upgrade']",
    resourceId: "rds:k4-rds09-new-bkp-oct-15-2019-2019-10-17-08-11",
    resourceArn:
      "arn:aws:rds:us-east-1:086775481754:snapshot:rds:k4-rds09-new-bkp-oct-15-2019-2019-10-17-08-11",
    serviceType: "rds",
    resourceType: "snapshot",
    currentCost: 798,
    savings: 798,
    playbookId: "",
    runbookId: null,
    tagged: "11/16/19 14:05",
    executionWindowStart: null,
    executionWindowEnd: null,
    dateExecuted: null,
    executionDetails: null,
    jiraCRTicket: null,
    jiraSOUTicket: "CO-76902",
    pcaApproved: null,
    pcaReviewDate: null,
    pcaComment: null,
    jiraEXTicket: null,
    status: "Pending Approval",
    customRunbookId: null,
    pcaRejectCategory: null,
    last_scan_begin: null,
    last_opp_find: "2/3/20 18:00",
    deprecated: 1,
    approvaljiraid: "tmp-95b0ad6e-865b-4118-ba8f-87a90bf9bd85",
    cr_status: null,
    workflow_type: "CO Opportunity",
    isCustomized: 0,
    excreationdate: null,
    additional_labels: null,
    overrideprojectkey: null,
    cloudType: "AWS",
    VCSIssue: null,
    VCSBranch: null,
    FixCommit: null,
  },
  {
    id: 50957,
    checkName: "aws_rds_backups",
    uniqueid: "aws_rds_backups_086775481754_k4-rds10-new-kcupgrade-28042019",
    version: 1,
    detailType: "AWS RDS Backups",
    source: "score.playbook",
    account: 86775481754,
    region: "us-east-1",
    time: "3/10/20 0:03",
    resources: "['k4-rds10-new-kcupgrade-28042019']",
    resourceId: "k4-rds10-new-kcupgrade-28042019",
    resourceArn:
      "arn:aws:rds:us-east-1:086775481754:snapshot:k4-rds10-new-kcupgrade-28042019",
    serviceType: "rds",
    resourceType: "snapshot",
    currentCost: 828.64,
    savings: 828.64,
    playbookId: "",
    runbookId: null,
    tagged: "11/16/19 14:05",
    executionWindowStart: null,
    executionWindowEnd: null,
    dateExecuted: null,
    executionDetails: null,
    jiraCRTicket: null,
    jiraSOUTicket: null,
    pcaApproved: "1",
    pcaReviewDate: "1/28/20 13:54",
    pcaComment: null,
    jiraEXTicket: null,
    status: "Unknown",
    customRunbookId: null,
    pcaRejectCategory: null,
    last_scan_begin: null,
    last_opp_find: "3/10/20 0:03",
    deprecated: 1,
    approvaljiraid: null,
    cr_status: null,
    workflow_type: null,
    isCustomized: 0,
    excreationdate: null,
    additional_labels: null,
    overrideprojectkey: null,
    cloudType: "AWS",
    VCSIssue: null,
    VCSBranch: null,
    FixCommit: null,
  },
];
const OpportunitiesPage: React.FC<Props> = () => {
  const onPaginate = (_page: number) => null;

  return (
    <>
      <Title>Opportunities</Title>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <OpportunitiesDataGrid
            data={opTableRows}
            loading={false}
            currentPage={0}
            totalPages={2}
            onPaginate={onPaginate}
          />
        </Grid>
      </Grid>
    </>
  );
};

OpportunitiesPage.displayName = "Opportunities Page";
export default OpportunitiesPage;
