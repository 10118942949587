import { ResponseError } from "api/errors";
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { useFetchData } from 'hooks/use-fetch-data';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

export type Query = {
  __typename?: 'Query';
  /** The ID of the object */
  node?: Maybe<Node>;
  admin?: Maybe<Admin>;
  /** Server version */
  version?: Maybe<Scalars['String']>;
  profile?: Maybe<User>;
  flows?: Maybe<Array<Maybe<CompanyFlow>>>;
  aws?: Maybe<Aws>;
  gcp?: Maybe<Gcp>;
  diagnostics?: Maybe<Diagnostics>;
  dashboard?: Maybe<Dashboard>;
  AthenaResults?: Maybe<AthenaResults>;
  athena?: Maybe<Athena>;
  process?: Maybe<CloudProcessing>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryDiagnosticsArgs = {
  flowId: Scalars['ID'];
};


export type QueryAthenaResultsArgs = {
  flowId: Scalars['ID'];
};


export type QueryAthenaArgs = {
  flowId: Scalars['ID'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type Admin = {
  __typename?: 'Admin';
  users?: Maybe<UserConnection>;
  companies?: Maybe<CompanyConnection>;
  flows?: Maybe<CompanyFlowConnection>;
};


export type AdminUsersArgs = {
  filters?: Maybe<UserFilter>;
  sort?: Maybe<Array<Maybe<UserSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AdminCompaniesArgs = {
  filters?: Maybe<CompanyFilter>;
  sort?: Maybe<Array<Maybe<CompanySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AdminFlowsArgs = {
  filters?: Maybe<CompanyFlowSetupModelFilter>;
  sort?: Maybe<Array<Maybe<CompanyFlowSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserEdge>>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `User` and its cursor. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** The item at the end of the edge */
  node?: Maybe<User>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type User = Node & {
  __typename?: 'User';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  company?: Maybe<Company>;
};


/** An enumeration. */
export enum UserStatus {
  Pending = 'PENDING',
  Verified = 'VERIFIED',
  Disabled = 'DISABLED'
}

export type Company = Node & {
  __typename?: 'Company';
  /** The ID of the object. */
  id: Scalars['ID'];
  uuid?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  setupStage?: Maybe<SetupStage>;
  projectKey: Scalars['String'];
  users?: Maybe<UserConnection>;
  cloudAccounts?: Maybe<CloudAccountConnection>;
  flows?: Maybe<CompanyFlowConnection>;
};


export type CompanyUsersArgs = {
  filters?: Maybe<UserFilter>;
  sort?: Maybe<Array<Maybe<UserSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CompanyCloudAccountsArgs = {
  sort?: Maybe<Array<Maybe<CloudAccountSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CompanyFlowsArgs = {
  filters?: Maybe<CompanyFlowSetupModelFilter>;
  sort?: Maybe<Array<Maybe<CompanyFlowSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum SetupStage {
  Initial = 'INITIAL',
  Script = 'SCRIPT',
  Role = 'ROLE',
  Region = 'REGION',
  Processing = 'PROCESSING',
  Results = 'RESULTS',
  Complete = 'COMPLETE'
}

export type UserFilter = {
  /** Exact match. */
  name?: Maybe<Scalars['String']>;
  /** Not match. */
  nameNe?: Maybe<Scalars['String']>;
  /** Case-sensitive containment test. */
  nameLike?: Maybe<Scalars['String']>;
  /** Case-insensitive containment test. */
  nameIlike?: Maybe<Scalars['String']>;
  /** In a given list. */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not in a given list. */
  nameNotIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Takes either `true` or `false`. */
  nameIsNull?: Maybe<Scalars['Boolean']>;
  /** Exact match. */
  email?: Maybe<Scalars['String']>;
  /** Not match. */
  emailNe?: Maybe<Scalars['String']>;
  /** In a given list. */
  emailIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Case-insensitive containment test. */
  emailIlike?: Maybe<Scalars['String']>;
  /** Conjunction of filters joined by ``AND``. */
  and?: Maybe<Array<UserFilter>>;
  /** Conjunction of filters joined by ``OR``. */
  or?: Maybe<Array<UserFilter>>;
  /** Negation of filters. */
  not?: Maybe<UserFilter>;
  /** email = admin */
  isAdmin?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum UserSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

export type CloudAccountConnection = {
  __typename?: 'CloudAccountConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CloudAccountEdge>>;
};

/** A Relay edge containing a `CloudAccount` and its cursor. */
export type CloudAccountEdge = {
  __typename?: 'CloudAccountEdge';
  /** The item at the end of the edge */
  node?: Maybe<CloudAccount>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CloudAccount = Node & {
  __typename?: 'CloudAccount';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId?: Maybe<Scalars['Int']>;
  cloud?: Maybe<Cloud>;
  account?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  arn?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  flow?: Maybe<CompanyFlowConnection>;
  regions?: Maybe<CloudRegionsConnection>;
  processes?: Maybe<CloudProcessingConnection>;
};


export type CloudAccountFlowArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CloudAccountRegionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CloudAccountProcessesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum Cloud {
  Aws = 'AWS',
  Gcp = 'GCP',
  Azure = 'AZURE',
  Dc = 'DC'
}

export type CompanyFlowConnection = {
  __typename?: 'CompanyFlowConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyFlowEdge>>;
};

/** A Relay edge containing a `CompanyFlow` and its cursor. */
export type CompanyFlowEdge = {
  __typename?: 'CompanyFlowEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyFlow>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyFlow = Node & {
  __typename?: 'CompanyFlow';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId?: Maybe<Scalars['Int']>;
  cloudId?: Maybe<Scalars['Int']>;
  setupType?: Maybe<SetupTypes>;
  stage?: Maybe<FlowStage>;
  page?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  cloud?: Maybe<CloudAccount>;
};

/** An enumeration. */
export enum SetupTypes {
  CloudAws = 'cloud_aws',
  CloudGcp = 'cloud_gcp',
  SavingsPlanQuery = 'savings_plan_query',
  SavingsPlanAthena = 'savings_plan_athena'
}

/** An enumeration. */
export enum FlowStage {
  Setup = 'setup',
  Processing = 'processing',
  Results = 'results'
}

export type CloudRegionsConnection = {
  __typename?: 'CloudRegionsConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CloudRegionsEdge>>;
};

/** A Relay edge containing a `CloudRegions` and its cursor. */
export type CloudRegionsEdge = {
  __typename?: 'CloudRegionsEdge';
  /** The item at the end of the edge */
  node?: Maybe<CloudRegions>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CloudRegions = Node & {
  __typename?: 'CloudRegions';
  /** The ID of the object. */
  id: Scalars['ID'];
  cloudId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  cloudAccount?: Maybe<CloudAccount>;
};

export type CloudProcessingConnection = {
  __typename?: 'CloudProcessingConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CloudProcessingEdge>>;
};

/** A Relay edge containing a `CloudProcessing` and its cursor. */
export type CloudProcessingEdge = {
  __typename?: 'CloudProcessingEdge';
  /** The item at the end of the edge */
  node?: Maybe<CloudProcessing>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CloudProcessing = Node & {
  __typename?: 'CloudProcessing';
  /** The ID of the object. */
  id: Scalars['ID'];
  cloudId?: Maybe<Scalars['Int']>;
  status?: Maybe<CloudProcessingStatus>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  analyzed?: Maybe<Scalars['Float']>;
  cloudAccount?: Maybe<CloudAccount>;
};

/** An enumeration. */
export enum CloudProcessingStatus {
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
  Processing = 'PROCESSING',
  Done = 'DONE',
  Error = 'ERROR'
}


/** An enumeration. */
export enum CloudAccountSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CloudAsc = 'CLOUD_ASC',
  CloudDesc = 'CLOUD_DESC',
  AccountAsc = 'ACCOUNT_ASC',
  AccountDesc = 'ACCOUNT_DESC',
  RoleNameAsc = 'ROLE_NAME_ASC',
  RoleNameDesc = 'ROLE_NAME_DESC',
  ArnAsc = 'ARN_ASC',
  ArnDesc = 'ARN_DESC'
}

export type CompanyFlowSetupModelFilter = {
  /** Exact match. */
  stage?: Maybe<Scalars['String']>;
  /** Not match. */
  stageNe?: Maybe<Scalars['String']>;
  /** In a given list. */
  stageIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Conjunction of filters joined by ``AND``. */
  and?: Maybe<Array<CompanyFlowSetupModelFilter>>;
  /** Conjunction of filters joined by ``OR``. */
  or?: Maybe<Array<CompanyFlowSetupModelFilter>>;
  /** Negation of filters. */
  not?: Maybe<CompanyFlowSetupModelFilter>;
};

/** An enumeration. */
export enum CompanyFlowSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CloudIdAsc = 'CLOUD_ID_ASC',
  CloudIdDesc = 'CLOUD_ID_DESC',
  SetupTypeAsc = 'SETUP_TYPE_ASC',
  SetupTypeDesc = 'SETUP_TYPE_DESC',
  StageAsc = 'STAGE_ASC',
  StageDesc = 'STAGE_DESC',
  PageAsc = 'PAGE_ASC',
  PageDesc = 'PAGE_DESC'
}

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEdge>>;
};

/** A Relay edge containing a `Company` and its cursor. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<Company>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyFilter = {
  /** Case-insensitive containment test. */
  nameIlike?: Maybe<Scalars['String']>;
  /** Case-insensitive containment test. */
  projectKeyIlike?: Maybe<Scalars['String']>;
  /** Conjunction of filters joined by ``AND``. */
  and?: Maybe<Array<CompanyFilter>>;
  /** Conjunction of filters joined by ``OR``. */
  or?: Maybe<Array<CompanyFilter>>;
  /** Negation of filters. */
  not?: Maybe<CompanyFilter>;
};

/** An enumeration. */
export enum CompanySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SetupStageAsc = 'SETUP_STAGE_ASC',
  SetupStageDesc = 'SETUP_STAGE_DESC',
  ProjectKeyAsc = 'PROJECT_KEY_ASC',
  ProjectKeyDesc = 'PROJECT_KEY_DESC'
}

export type Aws = {
  __typename?: 'AWS';
  services?: Maybe<Array<Maybe<CloudService>>>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CloudService = {
  __typename?: 'CloudService';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
};

export type Gcp = {
  __typename?: 'GCP';
  services?: Maybe<Array<Maybe<CloudService>>>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Diagnostics = {
  __typename?: 'Diagnostics';
  services?: Maybe<Array<Maybe<OpportunityEvent>>>;
  top?: Maybe<Array<Maybe<OpportunityObj>>>;
  opportunitiesCount?: Maybe<Scalars['Int']>;
  annualCost?: Maybe<Scalars['Float']>;
};


export type DiagnosticsTopArgs = {
  count?: Maybe<Scalars['Int']>;
};

export type OpportunityEvent = {
  __typename?: 'OpportunityEvent';
  cloudService?: Maybe<CloudService>;
  awsService?: Maybe<CloudService>;
  resource?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  savings?: Maybe<Scalars['Float']>;
  cloud?: Maybe<Scalars['String']>;
};

export type OpportunityObj = {
  __typename?: 'OpportunityObj';
  checkName?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  resourceArn?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  currentCost?: Maybe<Scalars['Float']>;
  savings?: Maybe<Scalars['Float']>;
  workflowType?: Maybe<Scalars['String']>;
  cloudService?: Maybe<CloudService>;
  awsService?: Maybe<CloudService>;
  /** Status is an enum transformed into string */
  status?: Maybe<Scalars['String']>;
  /** Combines jiraSOUTicket, jiraEXTicket, jiraCRTicket */
  jiraTicket?: Maybe<Scalars['String']>;
  playbook?: Maybe<PlaybookObj>;
  resourceId?: Maybe<Scalars['String']>;
};

export type PlaybookObj = Node & {
  __typename?: 'PlaybookObj';
  checkName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  /** Title specific for rendering on diagnostics ui */
  titleDiagnostics?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['Int']>;
  optimizationCat?: Maybe<Scalars['String']>;
  savingsTypeCategory?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  cloudService?: Maybe<CloudService>;
  awsService?: Maybe<CloudService>;
  details?: Maybe<PlaybookDetailsObj>;
  cloudType?: Maybe<Scalars['String']>;
};

export type PlaybookDetailsObj = Node & {
  __typename?: 'PlaybookDetailsObj';
  checkName: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  decision?: Maybe<Scalars['String']>;
  rationale?: Maybe<Scalars['String']>;
  /** User friendly description based on rationale for diagnostics page */
  description?: Maybe<Scalars['String']>;
  algorithm?: Maybe<Scalars['String']>;
  impact?: Maybe<Scalars['String']>;
  probability?: Maybe<Scalars['String']>;
  remediation?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  cloudType?: Maybe<Scalars['String']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  playbook?: Maybe<PlaybookDetailsObj>;
  playbookList?: Maybe<Array<Maybe<PlaybookDetailsObj>>>;
};


export type DashboardPlaybookArgs = {
  checkName?: Maybe<Scalars['String']>;
};

export type AthenaResults = {
  __typename?: 'AthenaResults';
  status?: Maybe<AthenaStatus>;
  results?: Maybe<Scalars['String']>;
};

export enum AthenaStatus {
  Unsubmitted = 'unsubmitted',
  Submitted = 'submitted',
  Ready = 'ready'
}

export type Athena = {
  __typename?: 'athena';
  results?: Maybe<AthenaResults>;
  sql?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Mutations = {
  __typename?: 'Mutations';
  /** Signin(email:"foo@bar.com", password:"Abc1234") */
  Signin?: Maybe<SignIn>;
  Signout?: Maybe<SignOut>;
  /** Signup(email:"foo@bar.com", name:"Foo", password:"Abc1234", company:"Foobar") */
  Signup?: Maybe<SignUp>;
  /** VerifyCode(email:"foo@bar.com", code:"123456") { */
  VerifyCode?: Maybe<VerifyUser>;
  /** VerifyResend(email:"foo@bar.com") */
  VerifyResend?: Maybe<VerifyResend>;
  ForgotPassword?: Maybe<ForgotPassword>;
  ChangePassword?: Maybe<ChangePassword>;
  FlowSetup?: Maybe<FlowSetup>;
  FlowMoveBack?: Maybe<FlowMoveBack>;
  SetupScript?: Maybe<SetupScript>;
  SetupRole?: Maybe<SetupRole>;
  SetupRegions?: Maybe<SetupRegions>;
  SetupRunStartup?: Maybe<SetupRunStartup>;
  SetupProcessing?: Maybe<SetupProcessing>;
  SetupProcessInfo?: Maybe<SetupProcessInfo>;
  SendEmail?: Maybe<SendEmail>;
  AthenaSQL?: Maybe<AthenaSql>;
  AthenaShowSQL?: Maybe<AthenaShowSql>;
  AthenaCSV?: Maybe<AthenaCsv>;
  AthenaSetupParameters?: Maybe<AthenaSetupParameters>;
  AthenaSetupARN?: Maybe<AthenaSetupArn>;
};


export type MutationsSigninArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationsSignoutArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationsSignupArgs = {
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationsVerifyCodeArgs = {
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type MutationsVerifyResendArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationsForgotPasswordArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationsChangePasswordArgs = {
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationsFlowSetupArgs = {
  setupType: SetupTypes;
};


export type MutationsFlowMoveBackArgs = {
  delete?: Maybe<Scalars['Boolean']>;
  flowId: Scalars['ID'];
};


export type MutationsSetupScriptArgs = {
  cloud?: Maybe<Cloud>;
  flowId: Scalars['ID'];
  region?: Maybe<Scalars['String']>;
};


export type MutationsSetupRoleArgs = {
  arn?: Maybe<Scalars['String']>;
  credentials?: Maybe<Scalars['GenericScalar']>;
  flowId: Scalars['ID'];
};


export type MutationsSetupRegionsArgs = {
  flowId: Scalars['ID'];
  regions: Array<Maybe<Scalars['String']>>;
};


export type MutationsSetupRunStartupArgs = {
  flowId?: Maybe<Scalars['ID']>;
};


export type MutationsSetupProcessingArgs = {
  analyzed?: Maybe<Scalars['Float']>;
  company?: Maybe<Scalars['UUID']>;
  flowId?: Maybe<Scalars['ID']>;
};


export type MutationsSetupProcessInfoArgs = {
  analyzed?: Maybe<Scalars['Float']>;
  company?: Maybe<Scalars['UUID']>;
  flowId?: Maybe<Scalars['ID']>;
};


export type MutationsSendEmailArgs = {
  message: Message;
};


export type MutationsAthenaSqlArgs = {
  dbName: Scalars['String'];
  flowId: Scalars['ID'];
  tableName: Scalars['String'];
};


export type MutationsAthenaShowSqlArgs = {
  flowId: Scalars['ID'];
};


export type MutationsAthenaCsvArgs = {
  csv?: Maybe<Scalars['String']>;
  flowId: Scalars['ID'];
};


export type MutationsAthenaSetupParametersArgs = {
  dbName: Scalars['String'];
  flowId: Scalars['ID'];
  region: Scalars['String'];
  tableName: Scalars['String'];
  workgroup: Scalars['String'];
};


export type MutationsAthenaSetupArnArgs = {
  arn: Scalars['String'];
  flowId: Scalars['ID'];
};

/** Signin(email:"foo@bar.com", password:"Abc1234") */
export type SignIn = {
  __typename?: 'SignIn';
  token?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type SignOut = {
  __typename?: 'SignOut';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Signup(email:"foo@bar.com", name:"Foo", password:"Abc1234", company:"Foobar") */
export type SignUp = {
  __typename?: 'SignUp';
  me?: Maybe<User>;
  ok?: Maybe<Scalars['Boolean']>;
};

/** VerifyCode(email:"foo@bar.com", code:"123456") { */
export type VerifyUser = {
  __typename?: 'VerifyUser';
  ok?: Maybe<Scalars['Boolean']>;
};

/** VerifyResend(email:"foo@bar.com") */
export type VerifyResend = {
  __typename?: 'VerifyResend';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ForgotPassword = {
  __typename?: 'ForgotPassword';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ChangePassword = {
  __typename?: 'ChangePassword';
  ok?: Maybe<Scalars['Boolean']>;
};

export type FlowSetup = {
  __typename?: 'FlowSetup';
  ok?: Maybe<Scalars['Boolean']>;
  flow?: Maybe<CompanyFlow>;
};

export type FlowMoveBack = {
  __typename?: 'FlowMoveBack';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
  flow?: Maybe<CompanyFlow>;
};

export type SetupScript = {
  __typename?: 'SetupScript';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type SetupRole = {
  __typename?: 'SetupRole';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};


export type SetupRegions = {
  __typename?: 'SetupRegions';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type SetupRunStartup = {
  __typename?: 'SetupRunStartup';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type SetupProcessing = {
  __typename?: 'SetupProcessing';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type SetupProcessInfo = {
  __typename?: 'SetupProcessInfo';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type SendEmail = {
  __typename?: 'SendEmail';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Message = {
  toEmail: EmailTo;
  subject: Subject;
  phoneNumber?: Maybe<Scalars['String']>;
  body: Scalars['String'];
};

export enum EmailTo {
  Support = 'support',
  Sales = 'sales'
}

export enum Subject {
  Information = 'information',
  TechSupport = 'tech_support',
  SignUp = 'sign_up'
}

export type AthenaSql = {
  __typename?: 'AthenaSQL';
  ok?: Maybe<Scalars['Boolean']>;
  sql?: Maybe<Scalars['String']>;
};

export type AthenaShowSql = {
  __typename?: 'AthenaShowSQL';
  ok?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type AthenaCsv = {
  __typename?: 'AthenaCSV';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AthenaSetupParameters = {
  __typename?: 'AthenaSetupParameters';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AthenaSetupArn = {
  __typename?: 'AthenaSetupARN';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AthenaCsvMutationVariables = Exact<{
  flowId: Scalars['ID'];
}>;


export type AthenaCsvMutation = (
  { __typename?: 'Mutations' }
  & { AthenaCSV?: Maybe<(
    { __typename?: 'AthenaCSV' }
    & Pick<AthenaCsv, 'ok'>
  )> }
);

export type AthenaSqlMutationVariables = Exact<{
  dbName: Scalars['String'];
  flowId: Scalars['ID'];
  tableName: Scalars['String'];
}>;


export type AthenaSqlMutation = (
  { __typename?: 'Mutations' }
  & { AthenaSQL?: Maybe<(
    { __typename?: 'AthenaSQL' }
    & Pick<AthenaSql, 'sql'>
  )> }
);

export type AthenaSetupArnMutationVariables = Exact<{
  arn: Scalars['String'];
  flowId: Scalars['ID'];
}>;


export type AthenaSetupArnMutation = (
  { __typename?: 'Mutations' }
  & { AthenaSetupARN?: Maybe<(
    { __typename?: 'AthenaSetupARN' }
    & Pick<AthenaSetupArn, 'ok'>
  )> }
);

export type AthenaSetupParametersMutationVariables = Exact<{
  dbName: Scalars['String'];
  flowId: Scalars['ID'];
  region: Scalars['String'];
  tableName: Scalars['String'];
  workgroup: Scalars['String'];
}>;


export type AthenaSetupParametersMutation = (
  { __typename?: 'Mutations' }
  & { AthenaSetupParameters?: Maybe<(
    { __typename?: 'AthenaSetupParameters' }
    & Pick<AthenaSetupParameters, 'ok'>
  )> }
);

export type AthenaShowSqlMutationVariables = Exact<{
  flowId: Scalars['ID'];
}>;


export type AthenaShowSqlMutation = (
  { __typename?: 'Mutations' }
  & { AthenaShowSQL?: Maybe<(
    { __typename?: 'AthenaShowSQL' }
    & Pick<AthenaShowSql, 'ok'>
  )> }
);

export type AthenaPresignedUrlQueryVariables = Exact<{
  flowId: Scalars['ID'];
}>;


export type AthenaPresignedUrlQuery = (
  { __typename?: 'Query' }
  & { athena?: Maybe<(
    { __typename?: 'athena' }
    & Pick<Athena, 'url'>
  )> }
);

export type AthenaResultsQueryVariables = Exact<{
  flowId: Scalars['ID'];
}>;


export type AthenaResultsQuery = (
  { __typename?: 'Query' }
  & { AthenaResults?: Maybe<(
    { __typename?: 'AthenaResults' }
    & Pick<AthenaResults, 'status' | 'results'>
  )> }
);

export type AthenaSqlQueryQueryVariables = Exact<{
  flowId: Scalars['ID'];
}>;


export type AthenaSqlQueryQuery = (
  { __typename?: 'Query' }
  & { athena?: Maybe<(
    { __typename?: 'athena' }
    & Pick<Athena, 'sql'>
  )> }
);

export type CompanyFFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'uuid' | 'name' | 'setupStage'>
  & { cloudAccounts?: Maybe<(
    { __typename?: 'CloudAccountConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'CloudAccountEdge' }
      & { node?: Maybe<(
        { __typename?: 'CloudAccount' }
        & Pick<CloudAccount, 'cloud'>
      )> }
    )>> }
  )> }
);

export type FlowFFragment = (
  { __typename?: 'CompanyFlow' }
  & Pick<CompanyFlow, 'id' | 'stage' | 'page' | 'setupType'>
);

export type UserFFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'status'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & CompanyFFragment
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  code?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutations' }
  & { ChangePassword?: Maybe<(
    { __typename?: 'ChangePassword' }
    & Pick<ChangePassword, 'ok'>
  )> }
);

export type FlowSetupMutationVariables = Exact<{
  setupType: SetupTypes;
}>;


export type FlowSetupMutation = (
  { __typename?: 'Mutations' }
  & { FlowSetup?: Maybe<(
    { __typename?: 'FlowSetup' }
    & Pick<FlowSetup, 'ok'>
    & { flow?: Maybe<(
      { __typename?: 'CompanyFlow' }
      & Pick<CompanyFlow, 'id'>
    )> }
  )> }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutations' }
  & { ForgotPassword?: Maybe<(
    { __typename?: 'ForgotPassword' }
    & Pick<ForgotPassword, 'ok'>
  )> }
);

export type SetupRegionsMutationVariables = Exact<{
  flowId: Scalars['ID'];
  regions: Array<Scalars['String']> | Scalars['String'];
}>;


export type SetupRegionsMutation = (
  { __typename?: 'Mutations' }
  & { SetupRegions?: Maybe<(
    { __typename?: 'SetupRegions' }
    & Pick<SetupRegions, 'ok'>
  )> }
);

export type SetupRoleMutationVariables = Exact<{
  credentials: Scalars['GenericScalar'];
  flowId: Scalars['ID'];
}>;


export type SetupRoleMutation = (
  { __typename?: 'Mutations' }
  & { SetupRole?: Maybe<(
    { __typename?: 'SetupRole' }
    & Pick<SetupRole, 'ok'>
  )> }
);

export type SetupScriptMutationVariables = Exact<{
  region?: Maybe<Scalars['String']>;
  cloud?: Maybe<Cloud>;
  flowId: Scalars['ID'];
}>;


export type SetupScriptMutation = (
  { __typename?: 'Mutations' }
  & { SetupScript?: Maybe<(
    { __typename?: 'SetupScript' }
    & Pick<SetupScript, 'ok'>
  )> }
);

export type SigninMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SigninMutation = (
  { __typename?: 'Mutations' }
  & { Signin?: Maybe<(
    { __typename?: 'SignIn' }
    & Pick<SignIn, 'token'>
    & { me?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type SignoutMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SignoutMutation = (
  { __typename?: 'Mutations' }
  & { Signout?: Maybe<(
    { __typename?: 'SignOut' }
    & Pick<SignOut, 'ok'>
  )> }
);

export type SignupMutationVariables = Exact<{
  company: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignupMutation = (
  { __typename?: 'Mutations' }
  & { Signup?: Maybe<(
    { __typename?: 'SignUp' }
    & { me?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )> }
  )> }
);

export type VerifyCodeMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
}>;


export type VerifyCodeMutation = (
  { __typename?: 'Mutations' }
  & { VerifyCode?: Maybe<(
    { __typename?: 'VerifyUser' }
    & Pick<VerifyUser, 'ok'>
  )> }
);

export type VerifyResendMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type VerifyResendMutation = (
  { __typename?: 'Mutations' }
  & { VerifyResend?: Maybe<(
    { __typename?: 'VerifyResend' }
    & Pick<VerifyResend, 'ok'>
  )> }
);

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & UserFFragment
  )>, flows?: Maybe<Array<Maybe<(
    { __typename?: 'CompanyFlow' }
    & FlowFFragment
  )>>> }
);

export type RegionsQueryVariables = Exact<{ [key: string]: never; }>;


export type RegionsQuery = (
  { __typename?: 'Query' }
  & { aws?: Maybe<(
    { __typename?: 'AWS' }
    & Pick<Aws, 'regions'>
  )>, gcp?: Maybe<(
    { __typename?: 'GCP' }
    & Pick<Gcp, 'regions'>
  )> }
);

export type SendEmailMutationVariables = Exact<{
  message: Message;
}>;


export type SendEmailMutation = (
  { __typename?: 'Mutations' }
  & { SendEmail?: Maybe<(
    { __typename?: 'SendEmail' }
    & Pick<SendEmail, 'ok'>
  )> }
);

export type DiagnosticDashboardQueryVariables = Exact<{
  flowId: Scalars['ID'];
}>;


export type DiagnosticDashboardQuery = (
  { __typename?: 'Query' }
  & { diagnostics?: Maybe<(
    { __typename?: 'Diagnostics' }
    & Pick<Diagnostics, 'annualCost' | 'opportunitiesCount'>
    & { services?: Maybe<Array<Maybe<(
      { __typename?: 'OpportunityEvent' }
      & Pick<OpportunityEvent, 'cost' | 'savings'>
      & { cloudService?: Maybe<(
        { __typename?: 'CloudService' }
        & Pick<CloudService, 'shortName'>
      )> }
    )>>>, top?: Maybe<Array<Maybe<(
      { __typename?: 'OpportunityObj' }
      & Pick<OpportunityObj, 'region' | 'savings' | 'resourceId'>
      & { playbook?: Maybe<(
        { __typename?: 'PlaybookObj' }
        & Pick<PlaybookObj, 'titleDiagnostics'>
        & { details?: Maybe<(
          { __typename?: 'PlaybookDetailsObj' }
          & Pick<PlaybookDetailsObj, 'description'>
        )> }
      )> }
    )>>> }
  )>, process?: Maybe<(
    { __typename?: 'CloudProcessing' }
    & Pick<CloudProcessing, 'start' | 'end' | 'analyzed'>
  )> }
);

export type FlowMoveBackMutationVariables = Exact<{
  delete?: Maybe<Scalars['Boolean']>;
  flowId: Scalars['ID'];
}>;


export type FlowMoveBackMutation = (
  { __typename?: 'Mutations' }
  & { FlowMoveBack?: Maybe<(
    { __typename?: 'FlowMoveBack' }
    & Pick<FlowMoveBack, 'ok'>
  )> }
);

export const FlowFFragmentDoc = `
    fragment FlowF on CompanyFlow {
  id
  stage
  page
  setupType
}
    `;
export const CompanyFFragmentDoc = `
    fragment CompanyF on Company {
  id
  uuid
  name
  setupStage
  cloudAccounts {
    edges {
      node {
        cloud
      }
    }
  }
}
    `;
export const UserFFragmentDoc = `
    fragment UserF on User {
  id
  name
  email
  status
  company {
    ...CompanyF
  }
}
    ${CompanyFFragmentDoc}`;
export const AthenaCsvDocument = `
    mutation AthenaCSV($flowId: ID!) {
  AthenaCSV(flowId: $flowId) {
    ok
  }
}
    `;
export const useAthenaCsvMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<AthenaCsvMutation, TError, AthenaCsvMutationVariables, TContext>) => 
    useMutation<AthenaCsvMutation, TError, AthenaCsvMutationVariables, TContext>(
      useFetchData<AthenaCsvMutation, AthenaCsvMutationVariables>(AthenaCsvDocument),
      options
    );
export const AthenaSqlDocument = `
    mutation AthenaSQL($dbName: String!, $flowId: ID!, $tableName: String!) {
  AthenaSQL(dbName: $dbName, flowId: $flowId, tableName: $tableName) {
    sql
  }
}
    `;
export const useAthenaSqlMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<AthenaSqlMutation, TError, AthenaSqlMutationVariables, TContext>) => 
    useMutation<AthenaSqlMutation, TError, AthenaSqlMutationVariables, TContext>(
      useFetchData<AthenaSqlMutation, AthenaSqlMutationVariables>(AthenaSqlDocument),
      options
    );
export const AthenaSetupArnDocument = `
    mutation AthenaSetupARN($arn: String!, $flowId: ID!) {
  AthenaSetupARN(arn: $arn, flowId: $flowId) {
    ok
  }
}
    `;
export const useAthenaSetupArnMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<AthenaSetupArnMutation, TError, AthenaSetupArnMutationVariables, TContext>) => 
    useMutation<AthenaSetupArnMutation, TError, AthenaSetupArnMutationVariables, TContext>(
      useFetchData<AthenaSetupArnMutation, AthenaSetupArnMutationVariables>(AthenaSetupArnDocument),
      options
    );
export const AthenaSetupParametersDocument = `
    mutation AthenaSetupParameters($dbName: String!, $flowId: ID!, $region: String!, $tableName: String!, $workgroup: String!) {
  AthenaSetupParameters(
    dbName: $dbName
    flowId: $flowId
    region: $region
    tableName: $tableName
    workgroup: $workgroup
  ) {
    ok
  }
}
    `;
export const useAthenaSetupParametersMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<AthenaSetupParametersMutation, TError, AthenaSetupParametersMutationVariables, TContext>) => 
    useMutation<AthenaSetupParametersMutation, TError, AthenaSetupParametersMutationVariables, TContext>(
      useFetchData<AthenaSetupParametersMutation, AthenaSetupParametersMutationVariables>(AthenaSetupParametersDocument),
      options
    );
export const AthenaShowSqlDocument = `
    mutation AthenaShowSQL($flowId: ID!) {
  AthenaShowSQL(flowId: $flowId) {
    ok
  }
}
    `;
export const useAthenaShowSqlMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<AthenaShowSqlMutation, TError, AthenaShowSqlMutationVariables, TContext>) => 
    useMutation<AthenaShowSqlMutation, TError, AthenaShowSqlMutationVariables, TContext>(
      useFetchData<AthenaShowSqlMutation, AthenaShowSqlMutationVariables>(AthenaShowSqlDocument),
      options
    );
export const AthenaPresignedUrlDocument = `
    query AthenaPresignedUrl($flowId: ID!) {
  athena(flowId: $flowId) {
    url
  }
}
    `;
export const useAthenaPresignedUrlQuery = <
      TData = AthenaPresignedUrlQuery,
      TError = ResponseError
    >(
      variables: AthenaPresignedUrlQueryVariables, 
      options?: UseQueryOptions<AthenaPresignedUrlQuery, TError, TData>
    ) => 
    useQuery<AthenaPresignedUrlQuery, TError, TData>(
      ['AthenaPresignedUrl', variables],
      useFetchData<AthenaPresignedUrlQuery, AthenaPresignedUrlQueryVariables>(AthenaPresignedUrlDocument).bind(null, variables),
      options
    );
export const AthenaResultsDocument = `
    query AthenaResults($flowId: ID!) {
  AthenaResults(flowId: $flowId) {
    status
    results
  }
}
    `;
export const useAthenaResultsQuery = <
      TData = AthenaResultsQuery,
      TError = ResponseError
    >(
      variables: AthenaResultsQueryVariables, 
      options?: UseQueryOptions<AthenaResultsQuery, TError, TData>
    ) => 
    useQuery<AthenaResultsQuery, TError, TData>(
      ['AthenaResults', variables],
      useFetchData<AthenaResultsQuery, AthenaResultsQueryVariables>(AthenaResultsDocument).bind(null, variables),
      options
    );
export const AthenaSqlQueryDocument = `
    query AthenaSQLQuery($flowId: ID!) {
  athena(flowId: $flowId) {
    sql
  }
}
    `;
export const useAthenaSqlQueryQuery = <
      TData = AthenaSqlQueryQuery,
      TError = ResponseError
    >(
      variables: AthenaSqlQueryQueryVariables, 
      options?: UseQueryOptions<AthenaSqlQueryQuery, TError, TData>
    ) => 
    useQuery<AthenaSqlQueryQuery, TError, TData>(
      ['AthenaSQLQuery', variables],
      useFetchData<AthenaSqlQueryQuery, AthenaSqlQueryQueryVariables>(AthenaSqlQueryDocument).bind(null, variables),
      options
    );
export const ChangePasswordDocument = `
    mutation ChangePassword($code: String, $oldPassword: String, $email: String!, $password: String!) {
  ChangePassword(
    code: $code
    oldPassword: $oldPassword
    email: $email
    password: $password
  ) {
    ok
  }
}
    `;
export const useChangePasswordMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<ChangePasswordMutation, TError, ChangePasswordMutationVariables, TContext>) => 
    useMutation<ChangePasswordMutation, TError, ChangePasswordMutationVariables, TContext>(
      useFetchData<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument),
      options
    );
export const FlowSetupDocument = `
    mutation FlowSetup($setupType: SetupTypes!) {
  FlowSetup(setupType: $setupType) {
    ok
    flow {
      id
    }
  }
}
    `;
export const useFlowSetupMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<FlowSetupMutation, TError, FlowSetupMutationVariables, TContext>) => 
    useMutation<FlowSetupMutation, TError, FlowSetupMutationVariables, TContext>(
      useFetchData<FlowSetupMutation, FlowSetupMutationVariables>(FlowSetupDocument),
      options
    );
export const ForgotPasswordDocument = `
    mutation ForgotPassword($email: String!) {
  ForgotPassword(email: $email) {
    ok
  }
}
    `;
export const useForgotPasswordMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<ForgotPasswordMutation, TError, ForgotPasswordMutationVariables, TContext>) => 
    useMutation<ForgotPasswordMutation, TError, ForgotPasswordMutationVariables, TContext>(
      useFetchData<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument),
      options
    );
export const SetupRegionsDocument = `
    mutation SetupRegions($flowId: ID!, $regions: [String!]!) {
  SetupRegions(flowId: $flowId, regions: $regions) {
    ok
  }
}
    `;
export const useSetupRegionsMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SetupRegionsMutation, TError, SetupRegionsMutationVariables, TContext>) => 
    useMutation<SetupRegionsMutation, TError, SetupRegionsMutationVariables, TContext>(
      useFetchData<SetupRegionsMutation, SetupRegionsMutationVariables>(SetupRegionsDocument),
      options
    );
export const SetupRoleDocument = `
    mutation SetupRole($credentials: GenericScalar!, $flowId: ID!) {
  SetupRole(credentials: $credentials, flowId: $flowId) {
    ok
  }
}
    `;
export const useSetupRoleMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SetupRoleMutation, TError, SetupRoleMutationVariables, TContext>) => 
    useMutation<SetupRoleMutation, TError, SetupRoleMutationVariables, TContext>(
      useFetchData<SetupRoleMutation, SetupRoleMutationVariables>(SetupRoleDocument),
      options
    );
export const SetupScriptDocument = `
    mutation SetupScript($region: String, $cloud: Cloud, $flowId: ID!) {
  SetupScript(region: $region, cloud: $cloud, flowId: $flowId) {
    ok
  }
}
    `;
export const useSetupScriptMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SetupScriptMutation, TError, SetupScriptMutationVariables, TContext>) => 
    useMutation<SetupScriptMutation, TError, SetupScriptMutationVariables, TContext>(
      useFetchData<SetupScriptMutation, SetupScriptMutationVariables>(SetupScriptDocument),
      options
    );
export const SigninDocument = `
    mutation Signin($email: String!, $password: String!) {
  Signin(email: $email, password: $password) {
    token
    me {
      id
    }
  }
}
    `;
export const useSigninMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SigninMutation, TError, SigninMutationVariables, TContext>) => 
    useMutation<SigninMutation, TError, SigninMutationVariables, TContext>(
      useFetchData<SigninMutation, SigninMutationVariables>(SigninDocument),
      options
    );
export const SignoutDocument = `
    mutation Signout($token: String!) {
  Signout(token: $token) {
    ok
  }
}
    `;
export const useSignoutMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SignoutMutation, TError, SignoutMutationVariables, TContext>) => 
    useMutation<SignoutMutation, TError, SignoutMutationVariables, TContext>(
      useFetchData<SignoutMutation, SignoutMutationVariables>(SignoutDocument),
      options
    );
export const SignupDocument = `
    mutation Signup($company: String!, $email: String!, $name: String!, $password: String!) {
  Signup(company: $company, email: $email, name: $name, password: $password) {
    me {
      email
    }
  }
}
    `;
export const useSignupMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SignupMutation, TError, SignupMutationVariables, TContext>) => 
    useMutation<SignupMutation, TError, SignupMutationVariables, TContext>(
      useFetchData<SignupMutation, SignupMutationVariables>(SignupDocument),
      options
    );
export const VerifyCodeDocument = `
    mutation VerifyCode($email: String!, $code: String!) {
  VerifyCode(email: $email, code: $code) {
    ok
  }
}
    `;
export const useVerifyCodeMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyCodeMutation, TError, VerifyCodeMutationVariables, TContext>) => 
    useMutation<VerifyCodeMutation, TError, VerifyCodeMutationVariables, TContext>(
      useFetchData<VerifyCodeMutation, VerifyCodeMutationVariables>(VerifyCodeDocument),
      options
    );
export const VerifyResendDocument = `
    mutation VerifyResend($email: String!) {
  VerifyResend(email: $email) {
    ok
  }
}
    `;
export const useVerifyResendMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyResendMutation, TError, VerifyResendMutationVariables, TContext>) => 
    useMutation<VerifyResendMutation, TError, VerifyResendMutationVariables, TContext>(
      useFetchData<VerifyResendMutation, VerifyResendMutationVariables>(VerifyResendDocument),
      options
    );
export const GetProfileDocument = `
    query GetProfile {
  me: profile {
    ...UserF
  }
  flows: flows {
    ...FlowF
  }
}
    ${UserFFragmentDoc}
${FlowFFragmentDoc}`;
export const useGetProfileQuery = <
      TData = GetProfileQuery,
      TError = ResponseError
    >(
      variables?: GetProfileQueryVariables, 
      options?: UseQueryOptions<GetProfileQuery, TError, TData>
    ) => 
    useQuery<GetProfileQuery, TError, TData>(
      ['GetProfile', variables],
      useFetchData<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument).bind(null, variables),
      options
    );
export const RegionsDocument = `
    query Regions {
  aws {
    regions
  }
  gcp {
    regions
  }
}
    `;
export const useRegionsQuery = <
      TData = RegionsQuery,
      TError = ResponseError
    >(
      variables?: RegionsQueryVariables, 
      options?: UseQueryOptions<RegionsQuery, TError, TData>
    ) => 
    useQuery<RegionsQuery, TError, TData>(
      ['Regions', variables],
      useFetchData<RegionsQuery, RegionsQueryVariables>(RegionsDocument).bind(null, variables),
      options
    );
export const SendEmailDocument = `
    mutation SendEmail($message: Message!) {
  SendEmail(message: $message) {
    ok
  }
}
    `;
export const useSendEmailMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SendEmailMutation, TError, SendEmailMutationVariables, TContext>) => 
    useMutation<SendEmailMutation, TError, SendEmailMutationVariables, TContext>(
      useFetchData<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument),
      options
    );
export const DiagnosticDashboardDocument = `
    query DiagnosticDashboard($flowId: ID!) {
  diagnostics(flowId: $flowId) {
    annualCost
    opportunitiesCount
    services {
      cost
      savings
      cloudService {
        shortName
      }
    }
    top {
      region
      savings
      resourceId
      playbook {
        titleDiagnostics
        details {
          description
        }
      }
    }
  }
  process {
    start
    end
    analyzed
  }
}
    `;
export const useDiagnosticDashboardQuery = <
      TData = DiagnosticDashboardQuery,
      TError = ResponseError
    >(
      variables: DiagnosticDashboardQueryVariables, 
      options?: UseQueryOptions<DiagnosticDashboardQuery, TError, TData>
    ) => 
    useQuery<DiagnosticDashboardQuery, TError, TData>(
      ['DiagnosticDashboard', variables],
      useFetchData<DiagnosticDashboardQuery, DiagnosticDashboardQueryVariables>(DiagnosticDashboardDocument).bind(null, variables),
      options
    );
export const FlowMoveBackDocument = `
    mutation FlowMoveBack($delete: Boolean, $flowId: ID!) {
  FlowMoveBack(delete: $delete, flowId: $flowId) {
    ok
  }
}
    `;
export const useFlowMoveBackMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<FlowMoveBackMutation, TError, FlowMoveBackMutationVariables, TContext>) => 
    useMutation<FlowMoveBackMutation, TError, FlowMoveBackMutationVariables, TContext>(
      useFetchData<FlowMoveBackMutation, FlowMoveBackMutationVariables>(FlowMoveBackDocument),
      options
    );